const Cookies = require('js-cookie');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('[data-new-window]').click(function(event) {
    event.preventDefault()
    window.open($(this).attr('href'), $(this).attr('data-new-window'))
});

$('[data-toggle-theme]').click(function(e) {
    e.preventDefault();
    var theme = Cookies.get('theme') === 'dark' ? 'light' : 'dark'
    Cookies.set('theme', theme, { expires: 365 });
    location.reload()
});

$('[data-remove]').click(function(e) {
    e.preventDefault();
    var item = $(this).attr('data-remove').length > 0 ? $(this).attr('data-remove') : 'item';
    if (!confirm('Delete this '+item+'?')) {
        return;
    }
    var $self = $(this);
    $.ajax({
        url: $self.attr('href'),
        method: 'DELETE'
    }).done(function(data){
        $self.parents('tr').hide();
        location.reload();
    });
})


$('.image-zoom').magnificPopup({type:'image'});
// $('.newsletter-zoom').magnificPopup({
//     type:'ajax',
//     mainClass: 'newsletter-popup'
// });
$('.newsletter-zoom').magnificPopup({
    type: 'iframe',
    iframe: {
        markup: '<style>.mfp-iframe-holder .mfp-content {max-width: 640px; height: 95%}</style>'+
                '<div class="mfp-iframe-scaler newsletter-popup">'+
                    '<div class="mfp-close"></div>'+
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'
    }
});
